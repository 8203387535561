.static-view {
	margin-top: var(--page-margin-top);

	.static-gallery {
		.next-prev-btns {
			.owl-nav {
				top: 40%;
			}
		}
	}

	.img-auto {
		width: 100%;
	}


}